$mainColor: #2196f3;
$secondColor: #cfe3f3;
$thirdColor: #add0ec;

body {
  background-color: #c6c6d4;
  color: $mainColor;
  //font-family: "80sFont", Verdana, sans-serif;
}

p {
  margin: 0px;
}
h1 {
  border-bottom: 1px solid $mainColor;
  text-align: center;
}

.mainContainer {
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.mainPageContainer {
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;
  &Horizontal {
    flex-direction: row;
    &Center {
      justify-content: center;
    }
  }
  &Vertical {
    flex-direction: column;
    &Center {
      justify-content: center;
      align-items: center;
    }
  }

  &SpaceBetween {
    justify-content: space-between;
  }
}

.banner {
  &Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
  }
  &TextLogo {
    margin-left: 1rem;
    font-size: xx-large;
  }
  &ImageLogo {
    border-radius: 1em;
  }
  &MenuElt {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: larger;
    margin-left: 1.5rem !important;
    padding-bottom: 0.5em;
  }
  &MenuConfiguration {
    height: 50px;
  }
  &SubmenuElt {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: larger;
    margin-left: 1.5rem !important;
    margin-right: 0.5rem !important;
    padding-bottom: 0.5em;
    border-bottom: none !important;
    &:hover {
      border-bottom: none !important;
      background-color: $secondColor;
    }
  }
}

.defaultFont {
  color: #ecec04;
  font-size: small;
}

.toolbarContainer {
  margin-top: 5px;
  & button {
    margin-right: 1em;
  }
}

.userSubscriptionValidity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
}
.listButton {
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $thirdColor;
  }
  & a {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;
      border-bottom: none;
    }
  }
}

.login-wrapper,
.signin-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & input {
    margin-top: 0.5em;
    margin-bottom: 1em;
    min-height: 2em;
    color: darkgreen;
  }
}

.wizard {
  margin-bottom: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  margin: 1em;
  & div {
    padding-left: 1em;
  }
}

.fade-out-message {
  opacity: 1;
  transition: opacity 5s ease;
  margin-bottom: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  margin: 1em;
}

.info-message {
  background-color: lightgreen;
  color: darkgreen;
}

.error-message {
  background-color: lightcoral;
  color: red;
}

.fade-out-message.fade-out {
  opacity: 0;
  transition: opacity 5s ease;
}

.fade-animation {
  opacity: 1; /* Commencez par masquer l'élément */
  animation: fade 3s linear 6s forwards;
  margin-bottom: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  margin: 1em;
}

@keyframes fade {
  0% {
    opacity: 0; /* À 0%, l'élément est invisible */
  }
  50% {
    opacity: 1; /* À 50%, l'élément est complètement visible (fade-in) */
  }
  100% {
    opacity: 0; /* À 100%, l'élément est à nouveau invisible (fade-out) */
  }
}

.error {
  color: red;
  margin: 1em;
  padding: 1em;
  background-color: lightcoral;
}

/* Material UI override */
.css-rtrcn9-MuiTablePagination-root {
  color: none !important;
}

.dataGridContainer {
  width: 100%;
}

.datagridRedLine {
  color: red;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.datePicker {
  border: 1px solid #aaa;
  border-radius: 4px;
  width: calc(100% - 38px);
  padding: 12px 10px 12px 25px !important;
  &:focus-within {
    border: 2px solid $mainColor;
    outline: none;
  }
  &:hover {
    border: 1px solid black;
  }
}

.react-datepicker__calendar-icon {
  //padding-left: 0.5em;
  //padding-top: 0.5em;
  margin-top: 0.1em;
}

.contractEditDialog,
.rentPaidDialog,
.priceEditDialog {
  min-height: 500px;
}

.calendarDialog {
  min-width: 240px;
}

.warningDialogMessage {
  background-color: orange;
  border: 1px solid darkorange;
  border-radius: 0.5em;
  margin-top: 1em;
  padding: 0.3em;
  & p {
    margin-bottom: 0.5em;
  }
}

//Datagrid bug fixing
//.css-ql19fo-MuiDataGrid-overlayWrapper {
//height: 30px !important;
//}

.MuiDataGrid-overlayWrapper {
  height: 30px !important;
}

//Datagrid bug fixing
.css-axafay-MuiDataGrid-virtualScroller {
  overflow: hidden !important;
}
/*
.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
}
*/

//form control personalization
.MuiFormControl-root {
  width: 100% !important;
}

.MuiLink-root:hover {
  text-decoration: none !important;
}

.endValidityWarning {
  background-color: orangered;
  border-radius: 1em;
  padding: 1em;
  color: white;
}

.subMenu {
  width: 200px;
  justify-content: left !important;
  padding: 0.5em !important;
  color: $mainColor !important;
}

.chargesAdjustment,
.summary {
  width: 100%;
  & table {
    width: 100%;
    & th {
      font-weight: bold;
      text-align: left;
    }
    & tfoot tr {
      margin-top: 1em;
      font-weight: bold;
    }
  }
  & .right {
    text-align: right;
  }

  &Title {
    padding-bottom: 0.5em;
    & div {
      font-weight: bold;
    }
  }

  &Footer {
    margin-top: 1em;
    padding-top: 0.5em;
    border-top: 1px solid $mainColor;
    & div {
      font-weight: bold;
    }
  }

  &Line {
    padding-bottom: 0.5em;
    & input {
      text-align: right;
    }
  }
  &Grid {
    //override default format given by MUI
    margin: 0 !important;
    width: 100% !important;
  }
}

.inputNumber {
  text-align: right;
  border: 1px solid $mainColor;
}

.createAccountButton {
  visibility: visible;
  margin-top: 1em;
  margin-bottom: 1em;
}

.resetPasswordForm,
.login-wrapper {
  & input {
    width: 100%;
  }
}

.footer {
  width: 100%;
  margin-top: 1em;
  border-top: 1px solid $mainColor;
  padding-top: 0.5em;
  display: flex;
  justify-content: space-between;
}

.notFoundMessage {
  margin: auto;
  width: 50%;
  text-align: center;
  border: 1px solid $mainColor;
  border-radius: 1em;
  padding-top: 3em;
  padding-bottom: 3em;
  margin-top: 3em;
  margin-bottom: 3em;
}
