@charset "UTF-8";
body {
  background-color: #c6c6d4;
  color: #2196f3;
}

p {
  margin: 0px;
}

h1 {
  border-bottom: 1px solid #2196f3;
  text-align: center;
}

.mainContainer {
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.mainPageContainer {
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;
}
.flexHorizontal {
  flex-direction: row;
}
.flexHorizontalCenter {
  justify-content: center;
}
.flexVertical {
  flex-direction: column;
}
.flexVerticalCenter {
  justify-content: center;
  align-items: center;
}
.flexSpaceBetween {
  justify-content: space-between;
}

.bannerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}
.bannerTextLogo {
  margin-left: 1rem;
  font-size: xx-large;
}
.bannerImageLogo {
  border-radius: 1em;
}
.bannerMenuElt {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: larger;
  margin-left: 1.5rem !important;
  padding-bottom: 0.5em;
}
.bannerMenuConfiguration {
  height: 50px;
}
.bannerSubmenuElt {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: larger;
  margin-left: 1.5rem !important;
  margin-right: 0.5rem !important;
  padding-bottom: 0.5em;
  border-bottom: none !important;
}
.bannerSubmenuElt:hover {
  border-bottom: none !important;
  background-color: #cfe3f3;
}

.defaultFont {
  color: #ecec04;
  font-size: small;
}

.toolbarContainer {
  margin-top: 5px;
}
.toolbarContainer button {
  margin-right: 1em;
}

.userSubscriptionValidity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
}

.listButton {
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  align-items: center;
}
.listButton:hover {
  background-color: #add0ec;
}
.listButton a {
  display: flex;
  align-items: center;
}
.listButton a:hover {
  text-decoration: none;
  border-bottom: none;
}

.login-wrapper,
.signin-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-wrapper input,
.signin-wrapper input {
  margin-top: 0.5em;
  margin-bottom: 1em;
  min-height: 2em;
  color: darkgreen;
}

.wizard {
  margin-bottom: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  margin: 1em;
}
.wizard div {
  padding-left: 1em;
}

.fade-out-message {
  opacity: 1;
  transition: opacity 5s ease;
  margin-bottom: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  margin: 1em;
}

.info-message {
  background-color: lightgreen;
  color: darkgreen;
}

.error-message {
  background-color: lightcoral;
  color: red;
}

.fade-out-message.fade-out {
  opacity: 0;
  transition: opacity 5s ease;
}

.fade-animation {
  opacity: 1; /* Commencez par masquer l'élément */
  animation: fade 3s linear 6s forwards;
  margin-bottom: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  margin: 1em;
}

@keyframes fade {
  0% {
    opacity: 0; /* À 0%, l'élément est invisible */
  }
  50% {
    opacity: 1; /* À 50%, l'élément est complètement visible (fade-in) */
  }
  100% {
    opacity: 0; /* À 100%, l'élément est à nouveau invisible (fade-out) */
  }
}
.error {
  color: red;
  margin: 1em;
  padding: 1em;
  background-color: lightcoral;
}

/* Material UI override */
.css-rtrcn9-MuiTablePagination-root {
  color: none !important;
}

.dataGridContainer {
  width: 100%;
}

.datagridRedLine {
  color: red;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.datePicker {
  border: 1px solid #aaa;
  border-radius: 4px;
  width: calc(100% - 38px);
  padding: 12px 10px 12px 25px !important;
}
.datePicker:focus-within {
  border: 2px solid #2196f3;
  outline: none;
}
.datePicker:hover {
  border: 1px solid black;
}

.react-datepicker__calendar-icon {
  margin-top: 0.1em;
}

.contractEditDialog,
.rentPaidDialog,
.priceEditDialog {
  min-height: 500px;
}

.calendarDialog {
  min-width: 240px;
}

.warningDialogMessage {
  background-color: orange;
  border: 1px solid darkorange;
  border-radius: 0.5em;
  margin-top: 1em;
  padding: 0.3em;
}
.warningDialogMessage p {
  margin-bottom: 0.5em;
}

.MuiDataGrid-overlayWrapper {
  height: 30px !important;
}

.css-axafay-MuiDataGrid-virtualScroller {
  overflow: hidden !important;
}

/*
.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
}
*/
.MuiFormControl-root {
  width: 100% !important;
}

.MuiLink-root:hover {
  text-decoration: none !important;
}

.endValidityWarning {
  background-color: orangered;
  border-radius: 1em;
  padding: 1em;
  color: white;
}

.subMenu {
  width: 200px;
  justify-content: left !important;
  padding: 0.5em !important;
  color: #2196f3 !important;
}

.chargesAdjustment,
.summary {
  width: 100%;
}
.chargesAdjustment table,
.summary table {
  width: 100%;
}
.chargesAdjustment table th,
.summary table th {
  font-weight: bold;
  text-align: left;
}
.chargesAdjustment table tfoot tr,
.summary table tfoot tr {
  margin-top: 1em;
  font-weight: bold;
}
.chargesAdjustment .right,
.summary .right {
  text-align: right;
}
.chargesAdjustmentTitle,
.summaryTitle {
  padding-bottom: 0.5em;
}
.chargesAdjustmentTitle div,
.summaryTitle div {
  font-weight: bold;
}
.chargesAdjustmentFooter,
.summaryFooter {
  margin-top: 1em;
  padding-top: 0.5em;
  border-top: 1px solid #2196f3;
}
.chargesAdjustmentFooter div,
.summaryFooter div {
  font-weight: bold;
}
.chargesAdjustmentLine,
.summaryLine {
  padding-bottom: 0.5em;
}
.chargesAdjustmentLine input,
.summaryLine input {
  text-align: right;
}
.chargesAdjustmentGrid,
.summaryGrid {
  margin: 0 !important;
  width: 100% !important;
}

.inputNumber {
  text-align: right;
  border: 1px solid #2196f3;
}

.createAccountButton {
  visibility: visible;
  margin-top: 1em;
  margin-bottom: 1em;
}

.resetPasswordForm input,
.login-wrapper input {
  width: 100%;
}

.footer {
  width: 100%;
  margin-top: 1em;
  border-top: 1px solid #2196f3;
  padding-top: 0.5em;
  display: flex;
  justify-content: space-between;
}

.notFoundMessage {
  margin: auto;
  width: 50%;
  text-align: center;
  border: 1px solid #2196f3;
  border-radius: 1em;
  padding-top: 3em;
  padding-bottom: 3em;
  margin-top: 3em;
  margin-bottom: 3em;
}/*# sourceMappingURL=main.css.map */